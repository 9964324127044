<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <MDBTabs v-model="currentLanguage" v-if="!PageLoading">
        <MDBTabNav fill>
          <template v-for="(language, k) in Languages" :key="k">
            <MDBTabItem :tabId="language.lang" :uuid="WebSettingsUUid">{{
              $t(`LANG.${language.lang}`)
            }}</MDBTabItem>
          </template>
        </MDBTabNav>
        <MDBTabContent>
          <template v-for="(language, k) in Languages" :key="k">
            <MDBTabPane :tabId="language.lang" class="pt-3">
              <WebManageForm
                v-model="language.lang"
                :data="
                  typeof AllSettings[language.lang] != 'undefined'
                    ? AllSettings[language.lang]
                    : {}
                "
                :uuid="WebSettingsUUid"
              ></WebManageForm>
            </MDBTabPane>
          </template>
        </MDBTabContent>
      </MDBTabs>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
.settings-img {
  width: 100%;
}
</style>

<script>
import {
  MDBTabs,
  MDBTabPane,
  MDBTabNav,
  MDBTabItem,
  MDBTabContent,
  MDBCard,
  MDBCardBody,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import ApiService from "@/core/services/api.service";
import { ref, reactive } from "vue";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from "@/components/Elements/PageLoading";
import WebManageForm from "@/components/Form/WebManageForm";

export default {
  name: "site-manage",
  setup() {
    const WebSettingsUUid = ref("7f6b9ad3-acb5-4a0c-bbaf-50b2fa677de8");
    const Languages = i18nService.languages;
    const AllSettings = reactive({});
    const PageLoading = ref(true);
    const currentLanguage = ref(i18nService.getActiveLanguage());


    const GetSettings = function (WebSettingsUUid, lang) {
      return new Promise((resolve, reject) => {
        ApiService.query(`/Admin/v1/sysconfig/web/${WebSettingsUUid}`, {
          params: { lang: lang },
        }).then((response) => {
          if (response.status == 200) {
            let data = response.data.data;
            if (data != null) {
              AllSettings[data.lang] = data;
            } else {
              AllSettings[lang] = { lang: lang };
            }
            resolve(true);
          } else {
            reject(false);
          }
        });
      });
    };

    return {
      PageLoading, //頁面載入特效
      WebSettingsUUid,
      GetSettings,
      AllSettings,
      Languages, //語言列表
      currentLanguage, //當前語言
    };
  },
  components: {
    Breadcrumbs,
    MDBTabs,
    MDBTabPane,
    MDBTabNav,
    MDBTabItem,
    MDBTabContent,
    MDBCard,
    MDBCardBody,
    PageLoading,
    WebManageForm,
  },
  mounted() {
    Promise.all(
      this.Languages.map((item) => {
        return this.GetSettings(this.WebSettingsUUid, item.lang);
      })
    )
      .then(() => {
        this.PageLoading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
