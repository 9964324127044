<template>
  <form @submit="Submit" class="form">
    <MDBRow class="g-3">
      <MDBCol md="6">
        <input type="hidden" value="modelValue" >
        <MDBRow>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">{{ $t('WEB-MANAGE.WEB_NAME') }}<span class="text-danger">*</span></label>
              <input type="text" class="form-control" :placeholder="$t('WEB-MANAGE.WEB_NAME')" v-model="Form.name.value">
              <span class="text-danger">{{ nameError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">{{ $t('WEB-MANAGE.WEB_COUNTRY') }}</label>
              <input type="text" class="form-control" :placeholder="$t('WEB-MANAGE.WEB_COUNTRY')" v-model="Form.country.value">
              <span class="text-danger">{{ countryError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">{{ $t('WEB-MANAGE.WEB_AREA') }}</label>
              <input type="text" class="form-control" :placeholder="$t('WEB-MANAGE.WEB_AREA')" v-model="Form.area.value">
              <span class="text-danger">{{ areaError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">{{ $t('WEB-MANAGE.ADDRESS') }}</label>
              <textarea v-model="Form.address.value" cols="10" rows="2" :placeholder="$t('WEB-MANAGE.ADDRESS')" class="form-control"></textarea>
              <span class="text-danger">{{ addressError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">{{ $t('WEB-MANAGE.PHONENUMBER') }}</label>
              <input type="text" class="form-control" :placeholder="$t('WEB-MANAGE.PHONENUMBER')" v-model="Form.phone.value">
              <span class="text-danger">{{ phoneError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">{{ $t('WEB-MANAGE.FAXNUMBER') }}</label>
              <input type="text" class="form-control" :placeholder="$t('WEB-MANAGE.FAXNUMBER')" v-model="Form.fax.value">
              <span class="text-danger">{{ faxError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">{{ $t('WEB-MANAGE.WEB_EMAIL') }}</label>
              <input type="text" class="form-control" :placeholder="$t('WEB-MANAGE.WEB_EMAIL')" v-model="Form.email.value">
              <span class="text-danger">{{ emailError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">{{ $t('WEB-MANAGE.SERVICETIME') }}</label>
              <input type="text" class="form-control" :placeholder="$t('WEB-MANAGE.SERVICETIME')" v-model="Form.service_time.value">
              <span class="text-danger">{{ service_timeError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">網站關鍵字</label>
              <input type="text" class="form-control" placeholder="網站關鍵字" v-model="Form.keyword.value" >
              <span class="text-danger">{{ keywordError }}</span>
            </div>
          </MDBCol>
          <MDBCol md="12" class="mb-2">
            <div>
              <label class="form-label text-dark">網站描述</label>
              <textarea class="form-control" placeholder="網站描述" v-model="Form.description.value"></textarea>
              <span class="text-danger">{{ descriptionError }}</span>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
    <MDBBtn color="primary" type="submit" :disabled="SubmitLoading">
      {{ $t("COMMON.SAVE") }}
      <span class="spinner-border Loading" :class="{ 'd-none':!SubmitLoading }" role="status" >
        <span class="visually-hidden">Loading...</span>
      </span>
    </MDBBtn>
  </form>
</template>

<script>
import { MDBRow, MDBBtn, MDBCol } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { useForm, useField } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import { computed, ref } from "vue";

export default {
  name: "WebManageForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol
  },
  props: {
    uuid: {
      type: String,
      default: ""
    },
    modelValue: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const SubmitLoading = ref(false);
    const basicSchema = {
      lang: yup.string().required().label(i18n.t("COMMON.LANGUAGE")),
      name: yup.string().required().label(i18n.t("WEB-MANAGE.WEB_NAME")),
      country: yup.string().label(i18n.t("WEB-MANAGE.WEB_COUNTRY")),
      area: yup.string().label(i18n.t("WEB-MANAGE.WEB_AREA")),
      address: yup.string().label(i18n.t("WEB-MANAGE.ADDRESS")),
      phone: yup.string().label(i18n.t("WEB-MANAGE.PHONENUMBER")),
      fax: yup.string().label(i18n.t("WEB-MANAGE.FAXNUMBER")),
      email: yup.string().email().label(i18n.t("WEB-MANAGE.WEB_EMAIL")),
      service_time: yup.string().label(i18n.t("WEB-MANAGE.SERVICETIME")),
      description: yup.string().label("網站描述"),
      keyword: yup.string().label("網站關鍵字"),
    };

    const FormSchema = yup.object(Object.assign(basicSchema));

    const { handleSubmit } = useForm({
      validationSchema: FormSchema
    });

    const { value: lang, errorMessage: langError } = useField('lang');
    const { value: name, errorMessage: nameError } = useField('name');
    const { value: country, errorMessage: countryError } = useField('country');
    const { value: area, errorMessage: areaError } = useField('area');
    const { value: address, errorMessage: addressError } = useField('address');
    const { value: phone, errorMessage: phoneError } = useField('phone');
    const { value: fax, errorMessage: faxError } = useField('fax');
    const { value: email, errorMessage: emailError } = useField('email');
    const { value: service_time, errorMessage: service_timeError } = useField('service_time');
    const { value: description, errorMessage: descriptionError } = useField('description');
    const { value: keyword, errorMessage: keywordError } = useField('keyword');

    const Submit = handleSubmit(values => {
      SubmitLoading.value = true;
      ApiService.update("/Admin/v1/sysconfig/web", props.uuid, values).then(response => {
        if (response.status == 200) {
          CommonService.AxiosHandle(response);
          SubmitLoading.value = false;
        }
      });
      return false;
    });

    const Form = {
      lang,
      name,
      country,
      area,
      address,
      phone,
      fax,
      email,
      service_time,
      description,
      keyword,
    };

    const SettingsData = computed({
      get: () => props.data,
      set: val => emit("update:data", val),
    });

    for(let type in SettingsData.value) {
      if(SettingsData.value[type] != null) {
        Form[type].value = SettingsData.value[type];   
      }
    }

    return {
      Form,
      SubmitLoading,
      Submit,
      langError,
      nameError,
      countryError,
      areaError,
      addressError,
      phoneError,
      faxError,
      emailError,
      service_timeError,
      descriptionError,
      keywordError,
    };
  }
};
</script>